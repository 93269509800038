import { useEffect, useState } from 'react';
import '../styles/base/parallax.scss';
import { getSetting } from './App';

const BackgroundParallax = () => {
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [starsStyle, setStarsStyle] = useState({});
    const [secondStarsStyle, setSecondStarsStyle] = useState({});
    const [asteroidsStyle, setAsteroidsStyle] = useState({});
    const [planetStyle, setPlanetStyle] = useState({});

    const updateParallax = (mouseX, mouseY, width, height) => {
        const _w = width / 2;
        const _h = height / 2;
        const _mouseX = mouseX;
        const _mouseY = mouseY;

        setBackgroundStyle({
            backgroundPosition: `${2 - (_mouseX - _w) * 0.0001}% ${2 - (_mouseY - _h) * 0.0001}%`
        });
        setStarsStyle({
            backgroundPosition: `${2 - (_mouseX - _w) * 0.0005}% ${2 - (_mouseY - _h) * 0.0005}%`
        });
        setSecondStarsStyle({
            backgroundPosition: `${2 - (_mouseX - _w) * 0.001}% ${2 - (_mouseY - _h) * 0.001}%`
        });
        setAsteroidsStyle({
            backgroundPosition: `${2 - (_mouseX - _w) * 0.003}% ${2 - (_mouseY - _h) * 0.005}%`
        });
        setPlanetStyle({
            backgroundPosition: `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.005}%`
        });
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            updateParallax(e.clientX, e.clientY, window.innerWidth, window.innerHeight);
        };

        const handleResize = () => {
            updateParallax(window.innerWidth / 2, window.innerHeight / 2, window.innerWidth, window.innerHeight);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('resize', handleResize);

        // Initial update on mount
        handleResize();

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return ( getSetting("background") ?
            <div id="pBG" className="background" style={backgroundStyle}>
                <div id="pBG" className="stars" style={starsStyle}>
                    <div id="pBG" className="anotherStars" style={secondStarsStyle}>
                        <div id="pBG" className="asteroids" style={asteroidsStyle}>
                            <div id="pBG" className="planets" style={planetStyle}></div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div id="pBG" style={{background: "#000"}} />
    );
};

export default BackgroundParallax;
