// @ts-nocheck
import {Layout, Window} from "../layouts";
import {Dimmer, LabeledList, Section, Stack} from "../components";
import {useEffect, useState} from "react";
import axios from "axios";

type AdminStat = {
    notesStat: {
        notes: number,
        watchlists: number,
        messages: number,
    },
    bansStat: {
        server: number,
        role: number,
        permaBans: number,
    },
    ticketsStat: {
        closed: number,
        resolved: number,
        icIssue: number,
        rejected: number,
    },
    hours: string,
    allHours: string,
    firstTime: string

}

type statisticsProps = {
    id?: number
}

const StatMenu = ({id}: statisticsProps) => {
    let [loading, setLoading] = useState(true)
    let [stats, setStats] = useState<AdminStat>()

    const getStatistics = async () => {
        try{
            setLoading(true)
            const response = await axios.get("https://staff.fluffy-frontier.ru/api/game/adminstat")
            setStats(response.data)
        }catch (e) {
            //
        }finally {
            setLoading(false)
        }

    }

    // @ts-ignore
    useEffect( () => {
        getStatistics().catch(console.error)
    }, [])

     return loading ? (
             <Window title="Statistics" width="250px" height="450px">
                <Window.Content>
                    <Dimmer>Загрузка...</Dimmer>
                </Window.Content>
             </Window>
         )
         :
         (
        <Window title="Statistics" width="300px" height="635px" id={id}>
            <Window.Content>
                <Section title="Нотесы">
                    <LabeledList>
                        <LabeledList.Item labelWrap label="Нотесы">{stats.notesStat.notes}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Вотчлисты">{stats.notesStat.watchlists}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Сообщения">{stats.notesStat.messages}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Всего">{stats.notesStat.notes + stats.notesStat.watchlists + stats.notesStat.messages}</LabeledList.Item>
                    </LabeledList>
                </Section>
                <Section title="Баны">
                    <LabeledList>
                        <LabeledList.Item labelWrap label="Серверные баны">{stats.bansStat.server}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Баны ролей">{stats.bansStat.role}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Перманентные баны">{stats.bansStat.permaBans}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Банов всего">{stats.bansStat.server + stats.bansStat.role}</LabeledList.Item>
                    </LabeledList>
                </Section>
                <Section title="Тикеты">
                    <LabeledList>
                        <LabeledList.Item labelWrap label="Закрыто">{stats.ticketsStat.closed}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="IC Issue">{stats.ticketsStat.icIssue}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Отказано">{stats.ticketsStat.rejected}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Решено">{stats.ticketsStat.resolved}</LabeledList.Item>
                        <LabeledList.Item labelWrap label="Всего 'решено'">{stats.ticketsStat.resolved + stats.ticketsStat.rejected + stats.ticketsStat.icIssue + stats.ticketsStat.closed}</LabeledList.Item>
                    </LabeledList>
                </Section>
                <Section title="Остальное">
                    <LabeledList>
                        <LabeledList.Item labelWrap label="Всего наиграно">{stats.allHours}</LabeledList.Item>
                        <LabeledList.Divider />
                        <LabeledList.Item labelWrap label="Отпедалено">{stats.hours}</LabeledList.Item>
                        <LabeledList.Divider />
                        <LabeledList.Item labelWrap label="Впервые примкнул к нам">{stats.firstTime}</LabeledList.Item>
                    </LabeledList>
                </Section>
            </Window.Content>
        </Window>
    )
}

export default StatMenu