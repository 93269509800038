import React, { useState, useEffect } from 'react';
import { classes } from '../common/react';
import { toTitleCase } from '../common/string';
import { PropsWithChildren, ReactNode } from 'react';
import { Icon } from '../components';
import { BoxProps } from '../components/Box';
import { UI_DISABLED, UI_INTERACTIVE, UI_UPDATE } from '../constants';
import {dragStartHandler, loadWindowPosition, getRandomPosition, dragTouchStartHandler} from '../drag';
import { Layout } from './Layout';
import {useAtom} from "jotai";
import {menusAtom} from "../atoms/menus";
import {getSetting} from "../views/App";
import "../styles/themes/syndicate.scss"
import "../styles/themes/abductor.scss"
import "../styles/themes/cardtable.scss"
import "../styles/themes/malfunction.scss"
import "../styles/themes/ntos_cat.scss"
import "../styles/themes/ntos_lightmode.scss"
import "../styles/themes/retro.scss"
import "../styles/themes/spookyconsole.scss"
import "../styles/themes/wizard.scss"

const DEFAULT_SIZE: [number, number] = [400, 600];

type Props = Partial<{
  buttons: ReactNode;
  canClose: boolean;
  height: number | string;
  theme: string;
  title: string;
  width: number | string;
  id: number;
}> &
    PropsWithChildren;

export const Window = (props: Props) => {
  const {
    canClose = true,
    theme,
    title = 'Untitled',
    children,
    buttons,
    width,
    height,
    id,
  } = props;

  const initialPosition = loadWindowPosition(title) || getRandomPosition();
  const [position, setPosition] = useState(initialPosition);
  const [menus, setMenus] = useAtom(menusAtom)
  const customTheme = getSetting("theme")

  useEffect(() => {
    return () => {
    };
  }, [customTheme]);

  let showDimmer;
  return (
      <Layout
          id={id}
          className="Window"
          theme={customTheme || theme}
          height={height}
          width={width}
          //@ts-ignore
          style={{ top: position.top, left: position.left, position: 'absolute' }}
      >
        <TitleBar
            className="Window__titleBar"
            title={title}
            status={2}
            onDragStart={(e) => dragStartHandler(e, title, setPosition)}
            onTouchDragStart={(e) => dragTouchStartHandler(e, title, setPosition)}
            onClose={() => {

                setMenus(menus.filter(item => item.id !== id))
            }}
            canClose={canClose}
        >
          {buttons}
        </TitleBar>
        <div className={classes(['Window__rest'])}>
          {children}
          {showDimmer && <div className="Window__dimmer" />}
        </div>
      </Layout>
  );
};

type ContentProps = Partial<{
  className: string;
  fitted: boolean;
  scrollable: boolean;
  vertical: boolean;
}> &
    BoxProps &
    PropsWithChildren;

const WindowContent = (props: ContentProps) => {
  const { className, fitted, children, ...rest } = props;

  return (
      <Layout.Content
          className={classes(['Window__content', className])}
          {...rest}
      >
        {(fitted && children) || (
            <div className="Window__contentPadding">{children}</div>
        )}
      </Layout.Content>
  );
};

Window.Content = WindowContent;

const statusToColor = (status) => {
  switch (status) {
    case UI_INTERACTIVE:
      return 'good';
    case UI_UPDATE:
      return 'average';
    case UI_DISABLED:
    default:
      return 'bad';
  }
};

type TitleBarProps = Partial<{
  canClose: boolean;
  className: string;
  onClose: (e) => void;
  onDragStart: (e) => void;
  onTouchDragStart: (e) => void;
  status: number;
  title: string;
}> &
    PropsWithChildren;

const TitleBar = (props: TitleBarProps) => {
  const {
    className,
    title,
    status,
    canClose,
    onDragStart,
    onTouchDragStart,
    onClose,
    children,
  } = props;

  const finalTitle =
      (typeof title === 'string' &&
          title === title.toLowerCase() &&
          toTitleCase(title)) ||
      title;

  return (
      <div className={classes(['TitleBar', className])}>
        {(status === undefined && (
            <Icon className="TitleBar__statusIcon" name="tools" opacity={0.5} />
        )) || (
            <Icon
                className="TitleBar__statusIcon"
                color={statusToColor(status)}
                name="eye"
            />
        )}
        <div
            className="TitleBar__dragZone"
            onMouseDown={(e) => onDragStart && onDragStart(e)}
            onTouchStart={(e) => onTouchDragStart && onTouchDragStart(e)}
        />
        <div className="TitleBar__title">
          {finalTitle}
          {!!children && <div className="TitleBar__buttons">{children}</div>}
        </div>
        { canClose && (
            <div className="TitleBar__close TitleBar__clickable" onClick={onClose}>
              ×
            </div>
        )}
      </div>
  );
};
