import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import Backgroundparallax from "./views/BackgroundParallax";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import TicketMenu from "./views/Ticket";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/ticket",
        element: <TicketMenu />
    }

])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
      <Backgroundparallax />
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
