import React from 'react';
import { Window } from "../layouts/Window";
import { Button, NoticeBox, Section, Stack } from "../components/index";
import "../styles/themes/admin.scss";
import "../styles/components/typewriter.scss";
import SettingsMenu from "./SettingsMenu";
import StatusesMenu from "./Statuses";
import {User, userAtom} from "../atoms/user";
import {useAtom} from "jotai";
import StatMenu from "./StatisticMenu";

type MainMenuProps = {
    openWindow: (component: React.ReactElement, onlyOne: boolean) => void;
    id?: number;
};



const MainMenu = ({ openWindow, id }: MainMenuProps) => {
    const [user, setUser] = useAtom(userAtom)

    return (
        <Window
            id={id}
            title={user.logged ? "Admin menu" : "Login"}
            width={user.logged ? "220px" : "300px"}
            height={user.logged ? "295px" : "235px"}
            theme={"admin"}
        >
            <Window.Content>
                {user.logged ? <AdminMenu openWindow={openWindow} user={user}/> : loginMenu()}
            </Window.Content>
        </Window>
    );
};

const loginMenu = () => {
    return (
        <Section fill title="Залогиниться">
            <Stack vertical>
                <Stack.Item>
                    <NoticeBox>
                        Я бы в действительности не хотел, чтобы этот инструмент попал в руки не-педалей.
                    </NoticeBox>
                </Stack.Item>
                <Stack.Item align="center">
                    Статус пользователя: {typeWriter()}?
                </Stack.Item>
                <Stack.Item>
                    <Button fluid align="center" color="good" onClick={() => {
                        window.location.pathname = "api/auth/login"
                    }}>Залогиниться</Button>
                </Stack.Item>
                <Stack.Item>
                    <Button fluid align="center" color="danger" onClick={() => {
                        window.location.href = "https://youtu.be/dQw4w9WgXcQ";
                    }}>Нет, иди нафик!</Button>
                </Stack.Item>
            </Stack>
        </Section>
    );
};

type AdminMenuProps = {
    openWindow: (component: React.ReactElement, onlyOne: boolean) => void;
    user: User;
};

const AdminMenu = ({ openWindow, user }: AdminMenuProps) => {
    return (
        <div>
            <NoticeBox info align="center">Привет, {user.name}! Сейчас ты - {user.role}[{user.accessLevel}]</NoticeBox>
            <Section>
                <Button fluid icon="file-code" tooltip="Откроет парсер (просмоторщик) логов" tooltipPosition="right" onClick={() => window.location.pathname="parser"}>Парсер</Button>
                <Button fluid icon="users" tooltip="Меню для старших ребят, редактор статусов игроков" tooltipPosition="right" onClick={() => {
                    openWindow(<StatusesMenu />, true)
                }}>Статусы</Button>
            </Section>
            <Section>
                <Button fluid icon="gear" onClick={() => openWindow(<SettingsMenu />, true)}>Настройки</Button>
                <Button fluid icon="star" onClick={() => {
                    openWindow(<StatMenu />, true)
                }}>Моя статистика</Button>
                <Button fluid icon="user" disabled>Игроки</Button>
            </Section>
            <Section>
                <Button.Confirm color="danger" icon="right-from-bracket" onClick={() => {
                    window.location.pathname="/api/auth/logout"
                }} fluid>Выйти</Button.Confirm>
            </Section>
        </div>
    );
};

const typeWriter = () => {
    return <span className="typewriter"></span>;
};

export default MainMenu;
