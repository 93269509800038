import {Window} from "../layouts";
import {Box, Button, Dropdown, Flex, NoticeBox, Section, Stack, Tooltip} from "../components";
import {PropsWithChildren, useState} from "react";
import {ButtonCheckbox} from "../components/Button";
import {changeSettings, getSetting, setDefaultSettings} from "./App";

type settingsProps = {
    id?: number
}

type settingProp = {
    name: string,
    description: string
}

const Setting = (props: settingProp & PropsWithChildren) => {
    const {name, description, children} = props
    return (
        <Flex align="center" pb={2}>
            <Flex.Item grow={1} pr={2} basis={0} ml={2}>
                <Tooltip content={description} position="bottom-start">
                    <Box as="span" style={{
                        borderBottom: '2px dotted rgba(255, 255, 255, 0.8)',
                    }}>
                        {name}
                    </Box>
                </Tooltip>
            </Flex.Item>
            <Flex.Item>
                {children}
            </Flex.Item>
        </Flex>
    )
}

const SettingsMenu = ({id}: settingsProps) => {


    let themeOptions = [
        {index: "admin", name: "Админ"},
        {index: "abductor", name: "Абдуктор"},
        {index: "malfunction", name: "Malf"},
        {index: "ntos_cat", name: "Kitty"},
        {index: "ntos_lightmode", name: "Светлая"},
        {index: "retro", name: "Ретро"},
        {index: "spookyconsole", name: "Посхалко"},
        {index: "wizard", name: "Волшебник"},
        {index: "ntos", name: "НТОС"},
        {index: "syndicate", name: "Синдикат"},
    ]

    const [theme, setTheme] = useState(getSetting("theme"))
    const [background, setBackground] = useState(getSetting("background") as boolean)
    return (
        <Window title="Settings" width="270px" height="240px" theme="ntos" id={id}>
            <Window.Content>
                <Section title="Основные" buttons={<Button color="danger" icon="rotate-right" onClick={() => {
                    setDefaultSettings()
                    window.location.reload()
                }}>Сбросить</Button>}>
                    <Setting name={"Тема"} description="Позволяет изменить тему интерфейса">
                        <Dropdown options={themeOptions.map(themeToSet => themeToSet.name)} onSelected={(e) => {
                            let selectedTheme = themeOptions.find(themeToFind => themeToFind.name === e)?.index
                            changeSettings("theme", selectedTheme )
                            setTheme(selectedTheme as string)
                            window.location.reload()
                        }} selected={themeOptions.find(themeToFind => themeToFind.index === theme)?.name}/>
                    </Setting>
                    <Setting name="Фон" description={"Будет ли включен параллакс или нет."}>
                        <ButtonCheckbox checked={background} onClick={() => {
                            changeSettings("background", !background)
                            setBackground(!background)
                            window.location.reload()
                        }}/>
                    </Setting>
                </Section>
                <Section>
                    <NoticeBox info>Да, кажется я понимаю бесполезность этого меню, но я так хотел его впихнуть)</NoticeBox>
                </Section>
            </Window.Content>

        </Window>
    )
}

export default SettingsMenu