import { Window } from "../layouts";
import {
    Button,
    Dimmer, Icon,
    Input, NumberInput,
    Section,
    Stack,
    Tabs, Tooltip
} from "../components";
import { useEffect, useState } from "react";
import axios from "axios";
import {useAtom} from "jotai";
import {Access, userAtom} from "../atoms/user";

type statusProps = {
    id?: number
}

const statusType = [
    {
        name: "Ветераны",
        value: "veteran"
    },
    {
        name: "Менторы",
        value: "mentor"
    },
    {
        name: "Донатеры",
        value: "donator"
    },
]

const StatusesMenu = ({ id }: statusProps) => {
    const [players, setPlayers] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [user] = useAtom(userAtom)

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get("https://staff.fluffy-frontier.ru/api/game/statuses")
            const json = response.data
            setPlayers(json)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData().catch(console.error)
    }, [])

    // @ts-ignore
    return (
        <Window title="Statuses" width="320px" height={(user.accessLevel && user.accessLevel > Access.seniorAdmin) ? "305px" : "235px"} theme="ntos" id={id}>
            <Window.Content>
                {loading && (<Dimmer>Загрузка...</Dimmer>)}
                {!loading && (<Statuses players={players} fetchData={fetchData}></Statuses>)}
            </Window.Content>
        </Window>
    )
}

const Statuses = ({ players, fetchData }) => {
    const [status, setStatus] = useState(0)
    const [filter, setFilter] = useState("")

    const [player, setPlayer] = useState("")
    const [days, setDays] = useState(0)

    const [user] = useAtom(userAtom)

    return (
        <Stack vertical fill>
            <Stack.Item>
                <Tabs fluid>
                    {statusType.map((statusType, index) =>
                        <Tabs.Tab selected={status === index} onClick={() => {
                            setStatus(index)
                        }}>{statusType.name}</Tabs.Tab>
                    )}
                </Tabs>
            </Stack.Item>
            <Stack.Item>
                <Section title="Пользователи" height="150px" scrollable fill buttons={<Input maxWidth="160px" placeholder="Поиск..." value={filter} onInput={(e, value) => {
                    setFilter(value)
                }}>Hi!</Input>}>
                    {
                        players[statusType[status].value].filter(player => player.ckey.includes(filter)).map((statusPlayer, index) => {
                            return (
                                <div className="FabricatorRecipe" key={index}>
                                    <Tooltip content={statusPlayer.who}>
                                        <div className="FabricatorRecipe__Button FabricatorRecipe__Button--icon">
                                            <Icon name="circle-info"></Icon>
                                        </div>
                                    </Tooltip>
                                    <Tooltip content={statusPlayer.when}>
                                        <div className="FabricatorRecipe__Button FabricatorRecipe__Button--icon">
                                            <Icon name="calendar"></Icon>
                                        </div>
                                    </Tooltip>

                                    <div className="FabricatorRecipe__Title">
                                        {statusPlayer.ckey}
                                    </div>
                                    { //@ts-ignore
                                        ((user?.accessLevel > Access.seniorAdmin && status!==2) || (user?.accessLevel == 4)) && (
                                            <Tooltip content="Удалить пользователя из списка">
                                                <div className="FabricatorRecipe__Button FabricatorRecipe__Button--icon" onClick={(e) => {
                                                    axios.post("/api/game/statuses", {
                                                        add: false,
                                                        rank: Object.keys(players)[status],
                                                        ckey: statusPlayer.ckey,
                                                        who: user.name
                                                    }).then((data) => {
                                                        if(data.status === 200){
                                                            fetchData(); // перезапрос данных и обновление состояния
                                                        }
                                                    })
                                                }}>
                                                    <Icon name="close"></Icon>
                                                </div>
                                            </Tooltip>
                                        )
                                    }
                                </div>
                            )
                        })}
                </Section>
            </Stack.Item>
            <Stack.Item>
                {//@ts-ignore
                    user.accessLevel > Access.seniorAdmin && (
                        <Section title="Добавление">
                            <Stack>
                                <Stack.Item grow={status === 2 ? 0.5 : 0.8}>
                                    <Input fluid placeholder="Ckey пользователя" value={player} onInput={(event, value) => {
                                        setPlayer(value)
                                    }}/>
                                </Stack.Item>
                                {
                                    status === 2 && (
                                        <Stack.Item grow={0.3}>
                                            <NumberInput maxValue={732} fluid value={days} onChange={(value) => {
                                                setDays(value)
                                            }} minValue={0} step={1}></NumberInput>
                                        </Stack.Item>
                                    )
                                }
                                <Stack.Item grow={0.2}>
                                    <Button color="good" icon="plus" disabled={(status == 2 && user.accessLevel && user.accessLevel < Access.allRights)} onClick={() => {
                                        axios.post("/api/game/statuses", {
                                            add: true,
                                            rank: Object.keys(players)[status],
                                            ckey: player,
                                            days: (status == 2 && user.accessLevel && user.accessLevel == Access.allRights) ? days : null,
                                            who: user.name
                                        }).then((data) => {
                                            if(data.status === 200){
                                                fetchData(); // перезапрос данных и обновление состояния
                                            }
                                        })
                                    }}>Добавить</Button>
                                </Stack.Item>
                            </Stack>
                        </Section>
                    )}
            </Stack.Item>
        </Stack>
    )
}

export default StatusesMenu
