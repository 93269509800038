import React, { useEffect } from 'react';
import MainMenu from "./MainMenu";
import '../styles/main.scss';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { menusAtom } from "../atoms/menus";
import {startUser, userAtom} from "../atoms/user";

library.add(fas);

const defaultSetting = {
    theme: "ntos",
    background: true,
}

export const changeSettings = (key, value) => {
    let currentSettings = JSON.parse(localStorage.getItem("с-settings") as string) as object
    currentSettings[key] = value
    localStorage.setItem("с-settings", JSON.stringify(currentSettings))
}
export const getSetting = (key) => {
    let response = localStorage.getItem("с-settings") as string
    try{
        response = JSON.parse(response)
        response = response[key]
    }catch (e){
        return false
    }
    return response
}
export const setDefaultSettings = () => {
    localStorage.setItem("с-settings", JSON.stringify(defaultSetting))
}

const App = () => {
    const [menus, setMenus] = useAtom(menusAtom);
    const [user, setUser] = useAtom(userAtom);

    const addMenu = (component: React.ReactElement, onlyOne: boolean) => {
        setMenus(prevMenus => {
            const existingMenuIndex = prevMenus.findIndex(menu => menu.component.type === component.type);
            if (onlyOne && existingMenuIndex !== -1) {
                // Replace existing menu if onlyOne is true
                const updatedMenus = [...prevMenus];
                updatedMenus[existingMenuIndex] = { id: prevMenus[existingMenuIndex].id, component, onlyOne };
                return updatedMenus;
            }
            // Add new menu
            const newMenu = {
                id: prevMenus.length > 0 ? Math.max(...prevMenus.map(menu => menu.id)) + 1 : 1,
                component,
                onlyOne,
            };
            return [...prevMenus, newMenu];
        });
    };

    const closeWindow = (id: number) => {
        setMenus(prevMenus => prevMenus.filter(menu => menu.id !== id));
    };

    // @ts-ignore
    useEffect(async () => {
        setUser(await startUser())
        if(!localStorage.getItem("с-settings")){
            setDefaultSettings()
        }
        if (menus.length === 0) {
            addMenu(<MainMenu openWindow={addMenu} />, true);
        }
    }, []);

    return (
        <div className="TGUI">
            {menus.map(window => (
                React.cloneElement(window.component, { id: window.id, closeWindow })
            ))}
        </div>
    );
};

export default App;
