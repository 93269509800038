// drag.ts

type Position = {
    top: number;
    left: number;
};

type DragStartData = {
    startX: number;
    startY: number;
    initialPosition: Position;
};

let dragData: DragStartData | null = null;
let draggingElement: HTMLElement | null = null;

const saveWindowPosition = (title: string, position: Position) => {
    localStorage.setItem(`window-position-${title}`, JSON.stringify(position));
};

const loadWindowPosition = (title: string): Position | null => {
    const position = localStorage.getItem(`window-position-${title}`);
    return position ? JSON.parse(position) : null;
};

export const dragStartHandler = (e: React.MouseEvent, title: string, setPosition: (position: Position) => void) => {
    const target = e.target as HTMLElement;
    const windowElement = target.closest('.Window') as HTMLElement;
    // @ts-ignore
    for (let elementsByClassNameElement of document.getElementsByClassName("Window")) {
        elementsByClassNameElement.style.zIndex = "0"
    }

    if (!windowElement) return;
    windowElement.style.zIndex = "1"

    draggingElement = windowElement;
    const rect = windowElement.getBoundingClientRect();
    dragData = {
        startX: e.clientX,
        startY: e.clientY,
        initialPosition: { top: rect.top, left: rect.left },
    };

    const handleMouseMove = (moveEvent: MouseEvent) => {
        if (!dragData || !draggingElement) return;

        const newLeft = dragData.initialPosition.left + (moveEvent.clientX - dragData.startX);
        const newTop = dragData.initialPosition.top + (moveEvent.clientY - dragData.startY);

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const elementWidth = draggingElement.offsetWidth;
        const elementHeight = draggingElement.offsetHeight;

        const newPosition = {
            left: Math.max(0, Math.min(newLeft, windowWidth - elementWidth)),
            top: Math.max(0, Math.min(newTop, windowHeight - elementHeight)),
        };

        setPosition(newPosition);
        saveWindowPosition(title, newPosition);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        dragData = null;
        draggingElement = null;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
};

export const dragTouchStartHandler = (e: React.TouchEvent, title: string, setPosition: (position: Position) => void) => {
    const target = e.target as HTMLElement;
    const windowElement = target.closest('.Window') as HTMLElement;
    // @ts-ignore
    for (let elementsByClassNameElement of document.getElementsByClassName("Window")) {
        elementsByClassNameElement.style.zIndex = "0"
    }

    if (!windowElement) return;
    windowElement.style.zIndex = "1"

    draggingElement = windowElement;
    const rect = windowElement.getBoundingClientRect();
    dragData = {
        startX: e.touches[0].clientX,
        startY: e.touches[0].clientY,
        initialPosition: { top: rect.top, left: rect.left },
    };

    const handleTouchMove = (moveEvent: TouchEvent) => {
        console.log(title)
        if (!dragData || !draggingElement) return;

        const newLeft = dragData.initialPosition.left + (moveEvent.touches[0].clientX - dragData.startX);
        const newTop = dragData.initialPosition.top + (moveEvent.touches[0].clientY - dragData.startY);

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const elementWidth = draggingElement.offsetWidth;
        const elementHeight = draggingElement.offsetHeight;

        const newPosition = {
            left: Math.max(0, Math.min(newLeft, windowWidth - elementWidth)),
            top: Math.max(0, Math.min(newTop, windowHeight - elementHeight)),
        };

        setPosition(newPosition);
        saveWindowPosition(title, newPosition);
    };

    const handleTouchUp = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchUp);
        dragData = null;
        draggingElement = null;
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchUp);
};

const getRandomPosition = (): Position => {
    const padding = 50; // padding from the edges of the viewport
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const randomLeft = Math.floor(Math.random() * (windowWidth - padding * 2))/2 + padding;
    const randomTop = Math.floor(Math.random() * (windowHeight - padding * 2))/2 + padding;
    return { top: randomTop, left: randomLeft };
};

export { loadWindowPosition, getRandomPosition };
