import { atom } from "jotai";
import { ReactElement } from "react";
import axios from "axios";

export type User = {
    name?: string;
    accessLevel?: number;
    role?: string;
    logged: boolean;
};

export const userAtom = atom<User>({logged: false});

export enum Access {
    noRights,
    defaultAdmin,
    seniorAdmin,
    specialAdmin,
    allRights,
    unrealRights
}

export const startUser = async () => {
    const response = await axios.get("https://staff.fluffy-frontier.ru/api/user/get")
    if(response.data.logged){
        const user: User = {
            name: response.data.name,
            accessLevel: response.data.access,
            role: response.data.role,
            logged: true
        }
        return user
    }else{
        return {
            logged: false
        } as User
    }
}