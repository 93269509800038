import { atom } from "jotai";
import { ReactElement } from "react";

type Window = {
    id: number;
    component: ReactElement;
    onlyOne: boolean;
};

export const menusAtom = atom<Window[]>([]);
