import {useNavigate, useSearchParams} from "react-router-dom";
import {Window} from "../layouts";
import {Button, Dimmer, Icon, Section, Stack} from "../components";
import {useEffect, useState} from "react";
import axios from "axios";

type Ticket = {
    timestamp: string;
    sender?: string;
    recipient?: string;
    message: string;
    action: string
}

type ticketProps = {
    id?: number
}

const TicketMenu = ({id}: ticketProps) => {
    const [searchParams] = useSearchParams()
    const roundID = searchParams.get("round")
    const ticketID = searchParams.get("ticket")

    const [ticket, setTicket] = useState<Ticket[]>()
    const [members, setMembers] = useState<String[]>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const getTicket = async () => {
        try {
            const response = await axios.post("https://staff.fluffy-frontier.ru/api/game/tickets", {
                round: roundID,
                id: ticketID
            })
            setTicket(response.data)
            const uniqueNicknames = new Set();

            response.data.forEach(ticket => {
                if(ticket.sender)
                    uniqueNicknames.add(ticket.sender);
                if(ticket.recipient)
                    uniqueNicknames.add(ticket.recipient);
            });
            setMembers(Array.from(uniqueNicknames) as String[])
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (roundID && ticketID) {
            getTicket().catch(console.error)
        } else {
            setError(true)
            setLoading(false)
        }
    }, [roundID, ticketID])

    useEffect(() => {
        if(error){
            setTimeout(() => {
                navigate("/")
            },3000)
        }
    }, [error, navigate])

    return (
        <Window title={`Ticket ${roundID || "?"}-${ticketID || "?"}`} height="400px" width="700px">
                {loading ? (
                    <Dimmer>
                        <Icon name="spinner" spin />
                    </Dimmer>
                ) : error ? (
                    <Dimmer >
                        Такого тикета нет
                    </Dimmer>
                ) : (
                    <Window.Content>
                        <Section title="Пользователи">
                            <Stack>
                                {members && members.map((member, i) => (
                                    <Stack.Item grow key={i}>
                                        <Button disabled fluid icon="user">{member}</Button>
                                    </Stack.Item>
                                ))}
                            </Stack>
                        </Section>
                        <Section title="Переписка" maxHeight={"280px"} fill scrollable buttons={
                            (<span>{ticket && ticket[0].timestamp.split(" ")[0]}</span>)
                        }>
                            <Stack vertical>
                                {ticket && ticket.map((ticket, index) => (
                                   <TicketLog key={index} timestamp={ticket.timestamp} sender={ticket.sender} message={ticket.message} action={ticket.action} recipient={ticket.recipient} />
                                ))}
                            </Stack>
                        </Section>
                    </Window.Content>
                )}
        </Window>
    )
}

const TicketLog = ({timestamp, action, sender, recipient, message}: Ticket, {key}) => {
    let ts = timestamp.split(" ")[1]
    let actionColor = "white"
    let author = ""
    switch (action){
        case "Reply":
            author = `${sender} → ${recipient}`
            break;

        case "Ticket Opened":
            actionColor = "good"
            author = `${sender}`
            break;

        case "Interaction":
            actionColor = "average"
            break;

        default:
            actionColor = "bad"
            break
    }

    const decodeHtmlEntities = (text) => {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    };

    return (
        <Stack.Item key={key} color={actionColor}>[{ts}] {author.length>0 && author + ": "}{decodeHtmlEntities(message.replaceAll("<br>", "\n").replaceAll(/(\<.+?\>)/gm, ""))}</Stack.Item>

    )
}

export default TicketMenu
